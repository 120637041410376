<template>
  <div class="com-item">
    <div class="w">
      <div class="wl-title3">
        <!-- 为什么要参与？ -->
        {{ $t("community.box2T") }}
      </div>
      <div class="ul">
        <div
          class="wl-item2"
          v-for="(item, index) in $t('community.box2ItemList')"
          :key="index"
        >
          <div class="img">
            <img
              :src="require(`../../assets/images/v4/com/box2${index + 1}.png`)"
              alt=""
            />
          </div>
          <div class="t">{{ item.title }}</div>
          <div class="link"></div>
          <div class="p pfont">
            {{ item.text }}
          </div>
         
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Store from "../../store";
export default {
  data() {
    return {
      uti: Store.uti,
      store: Store.state,
    };
  },
};
</script>

<style lang="less" scoped>
.com-item {
  // height: 854px;
  padding-top: 146px;
  padding-bottom: 148px;
  box-sizing: border-box;
  background-image: url("../../assets/images/v4/com/box2BG.png");
  background-repeat: no-repeat;
  background-position: right 450px;
  .w {
    // background-color: pink;
    // height: 854px;
    .ul {
      margin-top: 100px;
      // background-color: pink;
      width: 100%;
      // height: 500px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .wl-item2 {
        width: 446px;
        height: 380px;
        background: #ffffff;
        border: 1px solid #cccccc;
        box-shadow: 0px 2px 8px 0px rgba(184, 186, 200, 0.5);
        border-radius: 10px;
      }
    }
  }
}
</style>
