<template>
  <div class="home-box">
    <div class="w">
      <div class="wl-title2">
        <div class="gang"></div>
        <div class="t">
          <!-- PolySmartChain 社区 -->
          {{ $t('home.box8T') }}
        </div>
        <div class="gang"></div>
      </div>
      <div class="box">
        <div class="p pfont">
          <!-- PSC是一个全球性的去中心化网络，拥有来自世界各地的验证者和社区成员。
          加入 Discord 上的讨论并及时了解最新消息和公告。 -->
          {{ $t('home.box8ST') }}
        </div>
        <div class="ul">
          <a href="javascript:;">
            <div class="item" @click="uti.goPath(store.link.home.box8I1)">
              <img src="../../assets/images/v4/home/Twitter.png" alt="" />
              <div class="text">
                <!-- 关注最新消息 -->
                {{ $t('home.box8I1') }}
              </div>
            </div>
            <div class="item" @click="uti.goPath(store.link.home.box8I2)">
              <img src="../../assets/images/v4/home/Discord.png" alt="" />
              <div class="text">
                <!-- 参与其中  -->
                {{ $t('home.box8I2') }}
              </div>
            </div>
            <div class="item" @click="uti.goPath(store.link.home.box8I3)">
              <img src="../../assets/images/v4/home/Medium.png" alt="" />
              <div class="text">
                <!-- 检查进度 -->
                {{ $t('home.box8I3') }}
              </div>
            </div>
            <div class="item" @click="uti.goPath(store.link.home.box8I4)">
              <img src="../../assets/images/v4/home/Telegram.png" alt="" />
              <div class="text">
                <!-- 加入社区 -->
                {{ $t('home.box8I4') }}
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Store from '../../store';
export default {
  data() {
    return {
      uti: Store.uti,
      store: Store.state,
    };
  },
};
</script>

<style lang="less" scoped>
.home-box {
  // height: 100vh;
  // margin-top: -150px;
  // background-color: rgb(0, 47, 255);
  // min-height: 604px;
  margin-bottom: 250px;
  background-image: url('../../assets/images/v4/home/box8Bg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-top: 96px;
  padding-bottom: 40px;
  box-sizing: border-box;
  .box {
    // background-color: pink;
    margin-top: 56px;
    min-height: 366px;
    .p {
      width: 629px;
      font-size: 18px;
      font-weight: 500;
      color: #999999;
      margin: 0 auto;
      text-align: center;
    }
    .ul {
      display: flex;
      justify-content: center;
      margin-top: 108px;
      a {
        display: flex;
        justify-content: center;
        // margin-top: 108px;
      }
      .item {
        width: 238px;
        height: 174px;
        text-align: center;
        transition: 0.3s;
        img {
          margin-top: 35px;
          height: 51px;
        }
        .text {
          margin-top: 18px;
          font-size: 16px;
          color: #999;
        }
      }
      .item:hover {
        background: #edf6fe;
        box-shadow: 4px 4px 20px 0px rgba(167, 201, 237, 0.5);
        .text {
          font-weight: bold;
          color: #333;
        }
      }
    }
  }
}
</style>
