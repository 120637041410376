<template>
  <div class="wl-com">
    <com-box-1></com-box-1>
    <com-box-2></com-box-2>
    <!-- <com-box-3></com-box-3> -->
    <home-box-8></home-box-8>
  </div>
</template>
<script>
import HomeBox8 from "../home/home-box8.vue";
import ComBox1 from "./com-box1.vue";
import ComBox2 from "./com-box2.vue";
// import ComBox3 from "./com-box3.vue";
export default {
  components: { ComBox1, ComBox2, HomeBox8 },
  data() {
    return {};
  },
};
</script>
